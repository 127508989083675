<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot'
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="background-color: #f2c98a"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <a href="#" class="text-center mb-10">
            <img
              src="media/logos/logo-chekkr-color-big.png"
              class="max-h-70px"
              alt=""
            />
          </a>
          <h3
            class="font-weight-bolder text-center font-size-h4 font-size-h1-lg"
            style="color: #986923"
          >
            Психология под контролем <br />вместе с CHEKR
          </h3>
        </div>
        <div
          class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
          :style="{ backgroundImage: `url(${backgroundImage})` }"
        ></div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signin_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Добро пожаловать в Chekr
                </h3>
                <span class="text-muted font-weight-bold font-size-h4"
                  >Впервые?
                  <a
                    id="kt_login_signup"
                    class="text-primary font-weight-bolder"
                    @click="showForm('signup')"
                    >Регистрация</a
                  ></span
                >
              </div>
              <div class="form-group" v-show="'message' in errors">
                <div
                  role="alert"
                  aria-live="polite"
                  aria-atomic="true"
                  class="alert alert-danger"
                >
                  {{ errors.message }}
                </div>
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark"
                  >Email</label
                >
                <div
                  id="example-input-group-1"
                  label=""
                  label-for="example-input-1"
                >
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="text"
                    name="email"
                    ref="email"
                    v-model="form.email"
                    @change="delete errors.message"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Пароль</label
                  >
                  <a
                    class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                    id="kt_login_forgot"
                    @click="showForm('forgot')"
                    >Забыли пароль?</a
                  >
                </div>
                <div
                  id="example-input-group-2"
                  label=""
                  label-for="example-input-2"
                >
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="password"
                    name="password"
                    ref="password"
                    v-model="form.password"
                    autocomplete="off"
                    @change="delete errors.message"
                  />
                </div>
              </div>
              <div class="pb-lg-0 pb-5">
                <button
                  ref="kt_login_signin_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                >
                  Вход
                </button>
                <!-- <button
                  type="button"
                  class="btn btn-light-primary font-weight-bolder px-8 py-4 my-3 font-size-lg"
                >
                  <span class="svg-icon svg-icon-md">
                    <inline-svg
                      src="media/svg/social-icons/google.svg"
                    /> </span
                  >Sign in with Google
                </button> -->
              </div>
            </form>
          </div>
          <!--end::Signin-->
          <!--begin::Signup-->
          <div class="login-form login-signup">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signup_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Регистрация
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Введите данные для создания аккаунта
                </p>
              </div>
              <div class="form-group" v-show="'messageNew' in errors">
                <div
                  role="alert"
                  aria-live="polite"
                  aria-atomic="true"
                  class="alert alert-danger"
                >
                  {{ errors.messageNew }}
                </div>
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="text"
                  placeholder="Имя"
                  name="firstname"
                  ref="firstname"
                  autocomplete="off"
                  @change="delete errors.messageNew"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="text"
                  placeholder="Фамилия"
                  name="lastname"
                  ref="lastname"
                  autocomplete="off"
                  @change="delete errors.messageNew"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="email"
                  placeholder="Email"
                  name="email"
                  ref="remail"
                  autocomplete="off"
                  @change="delete errors.messageNew"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="text"
                  placeholder="Организация"
                  name="orgname"
                  ref="orgname"
                  autocomplete="off"
                  @change="delete errors.messageNew"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="password"
                  placeholder="Пароль"
                  name="password"
                  ref="rpassword"
                  autocomplete="off"
                  @change="delete errors.messageNew"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="password"
                  placeholder="Повтор пароля"
                  name="cpassword"
                  ref="cpassword"
                  autocomplete="off"
                  @change="delete errors.messageNew"
                />
              </div>
              <div class="form-group">
                <label class="checkbox mb-0">
                  <input
                    type="checkbox"
                    name="agree"
                    @change="delete errors.messageNew"
                  />
                  <span class="mr-2"></span>
                  Согласен
                  <a href="#" class="ml-2">с терминами и определниями</a>.
                </label>
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
                <button
                  ref="kt_login_signup_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                  style="width: 150px"
                >
                  Отправить
                </button>
                <button
                  type="button"
                  id="kt_login_signup_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signin')"
                >
                  Отмена
                </button>
              </div>
            </form>
          </div>
          <!--end::Signup-->
          <!--begin::Forgot-->
          <div class="login-form login-forgot">
            <!--begin::Form-->
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_forgot_form"
              ref="kt_login_forgot_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Забыли пароль ?
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Введите ваш e-mail для сброса пароля
                </p>
              </div>
              <div class="form-group" v-show="'messageRec' in errors">
                <div
                  role="alert"
                  aria-live="polite"
                  aria-atomic="true"
                  class="alert alert-danger"
                >
                  {{ errors.messageRec }}
                </div>
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="email"
                  placeholder="Email"
                  name="email"
                  ref="reemail"
                  autocomplete="off"
                  @change="delete errors.messageRec"
                />
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  ref="kt_login_forgot_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                >
                  Отправить
                </button>
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signin')"
                >
                  Отмена
                </button>
              </div>
            </form>
          </div>
          <!--end::Forgot-->
        </div>
        <!--begin::Content footer-->
        <div
          class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0"
        >
          <a
            href="https://chekr.ru/#prices"
            class="text-primary ml-10 font-weight-bolder font-size-h5"
            >Цены</a
          >
          <a
            href="https://chekr.ru/#contacts"
            class="text-primary ml-10 font-weight-bolder font-size-h5"
            >Контакты</a
          >
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script src="https://www.google.com/recaptcha/api.js?render=6Lc236wZAAAAANekEfdkPssBvk_yBDxtJLU-4sqT"></script>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import {
  LOGIN,
  LOGOUT,
  REGISTER,
  UPDATE_PASSWORD
} from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

export default {
  name: "login-1",
  data() {
    return {
      state: "signin",
      form: {
        email: null,
        password: null
      }
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    }
  },
  mounted() {
    const signin_form = KTUtil.getById("kt_login_signin_form");
    const signup_form = KTUtil.getById("kt_login_signup_form");
    const forgot_form = KTUtil.getById("kt_login_forgot_form");

    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Укажите e-mail"
            },
            emailAddress: {
              message: "Введите корректный e-mail адрес"
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: "Введите пароль"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv1 = formValidation(signup_form, {
      fields: {
        firstname: {
          validators: {
            notEmpty: {
              message: "Введите ваше имя"
            }
          }
        },
        lastname: {
          validators: {
            notEmpty: {
              message: "Введите вашу фамилию"
            }
          }
        },
        email: {
          validators: {
            notEmpty: {
              message: "Укажите e-mail"
            },
            emailAddress: {
              message: "Введите корректный e-mail адрес"
            }
          }
        },
        orgname: {
          validators: {
            notEmpty: {
              message: "Введите название вашей организации"
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: "Введите пароль"
            }
          }
        },
        cpassword: {
          validators: {
            notEmpty: {
              message: "Введите повтор пароля"
            },
            identical: {
              compare: function() {
                return signup_form.querySelector('[name="password"]').value;
              },
              message: "Пароли не совпадают!"
            }
          }
        },
        agree: {
          validators: {
            notEmpty: {
              message: "Вы должны принять термины и определения"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv2 = formValidation(forgot_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Укажите e-mail"
            },
            emailAddress: {
              message: "Введите корректный e-mail адрес"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv.on("core.form.valid", () => {
      var username = this.form.email;
      var password = this.form.password;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // send login request
      this.$store
        .dispatch(LOGIN, { username, password })
        // go to dashboard page after successfully login
        .then(() => {
          this.$store.getters.currentUser.user.groups.includes(1)
            ? this.$router
                .push({ name: "dashboard" })
                .catch(err => console.error(err))
            : this.$router
                .push({ name: "checklist" })
                .catch(err => console.error(err));
        })
        .catch(() => {});

      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    });

    this.fv.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Пожалуйста, введите корректные данные!",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false
      });
    });

    this.fv1.on("core.form.valid", () => {
      const email = this.$refs.remail.value;
      const password = this.$refs.rpassword.value;
      const name = this.$refs.firstname.value;
      const surname = this.$refs.lastname.value;
      const orgname = this.$refs.orgname.value;
      const recaptcha = "";

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signup_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // send register request
      this.$store
        .dispatch(REGISTER, {
          email: email,
          password: password,
          name: name,
          surname: surname,
          org_name: orgname,
          recaptcha: recaptcha
        })
        .then(() => this.$router.push({ name: "dashboard" }));

      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    });

    this.fv1.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Пожалуйста, введите корректные данные!",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false
      });
    });

    this.fv2.on("core.form.valid", () => {
      const username = this.$refs.reemail.value;
      const password = this.makePass(8);
      const theme = "Chekkr: вы запросили восстановление пароля";
      const message =
        "Новый пароль: " + password + "\n" + "С уважением, команда Chekr.ru.";
      const to = username;
      const no_token = "true";
      const recaptcha = "";

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_forgot_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      var vm = this;
      // send login request
      this.$store
        .dispatch(UPDATE_PASSWORD, {
          username: username,
          password: password,
          theme: theme,
          message: message,
          to: [to],
          no_token: no_token,
          recaptcha: recaptcha
        })
        .then(() => {
          Swal.fire({
            title: "Успешно!",
            text: "Новый пароль направлен Вам на почту!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
          this.state = "signin";
          vm.showForm("signin");
        })
        .catch(err => {
          console.log(err);
        });

      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    });

    this.fv2.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Пожалуйста, введите корректные данные!",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false
      });
    });
  },
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },
    makePass(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()[]";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    }
  }
};
</script>
